import { useQuery } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';

import { writeUntilToday } from './func';
import { CHUL } from './gql';

const DATES = [
  2,3, 6,7,8,  9,10, 13, 14,15, 16,17, 20,21,22, 23,24, 27,28,29,30,31
];
const DAYS = '목금월화수목금월화수목금월화수목금월화';

export function StudentsTable1() {
  const { loading, error, data } = useQuery(CHUL, {
    variables: { yearMonth: '202308' },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  console.log('data:', data);
  // let chul = [...data.chul.filter(s => s.pianoBan === '베토벤반' || s.pianoBan === '바하반')];

  let upper = [];
  for (let i = 0; i < DATES.length; i++) {
    if (DAYS[i] === '금')
      upper.push({
        no: i + 1,
        date: DATES[i],
        weekday: DAYS[i],
        borderRight: 'red',
      });
    else
      upper.push({
        no: i + 1,
        date: DATES[i],
        weekday: DAYS[i],
        borderRight: 'black',
      });
  }

  let jindoNums1 = [...data.chul];
  let jindoNums = jindoNums1.slice(0);
  // let jindoNums = jindoNums1;

  jindoNums = jindoNums.map((obj) => {
    let L = [...obj.getLessons];
    L = L.map((l) => {
      function addMethods(object, methods) {
        for (var name in methods) {
          object[name] = methods[name];
        }
      }
      var methods = {
        display: function () {
          alert(l.date + ' ' + l._id);
        },
        display1: async function () {
          const data = JSON.stringify({
            query: `mutation RotateTeacher {
              rotateTeacher(lessonId: "${l._id}")
            }`,
          });

          const response = await fetch('http://localhost:5000', {
            method: 'post',
            body: data,
            headers: {
              'Content-Type': 'application/json',
              'Content-Length': data.length,
              Authorization: 'some very secret key',
            },
          });
          const json = await response.json();
          window.location.reload();
          return json.data.rotateTeacher;
        },
      };
      var l1 = { ...l };
      addMethods(l1, methods);
      return l1;
    });
    return { ...obj, getLessons: [...L] };
  });

  return (
    <>
      <h5 style={{ color: 'red', textAlign: 'center', display: 'none' }}>
        studentTable1: Don't click!!
      </h5>
      <table style={{ width: '100%' }}>
        <thead>
          <tr className="first">
            <th></th>
            <th></th>
            <th></th>
            <th colSpan="2">수업일수</th>
            <th colSpan="2">참 고</th>
            <th></th>
            <th></th>
            {[...Array(DATES.length).keys()].map((n) => (
              <th key={uuidv4()}>{n + 1}</th>
            ))}
          </tr>
          <tr>
            <th width="1%" rowSpan="2">
              결제
            </th>
            <th width="1%" rowSpan="2">
              no
            </th>
            <th width="7%" rowSpan="2">
              이름
            </th>
            <th width="7%" rowSpan="2">
              학년
            </th>
            <th width="4%" rowSpan="2">
              ID
            </th>
            <th width="6%" rowSpan="2">
              바이올린
            </th>
            <th width="6%" rowSpan="2">
              피아노
            </th>
            <th width="5%" rowSpan="2">
              교재
            </th>
            <th width="2%" rowSpan="2">
              진도
            </th>
            {DATES.map((n) => (
              <th key={uuidv4()}>{n}</th>
            ))}
          </tr>
          <tr>
            {DAYS.split('').map((c) => (
              <th key={uuidv4()}>{c}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {jindoNums.map((row, idx) => (
            <tr key={row.name}>
              <td className="payday">{row.payday}</td>
              <td className="fee">{row.fee}</td>
              <td
                className={row.otherBan === '바만' ? 'name violinOnly' : 'name'}
              >
                {row.name}
              </td>
              <td>{row.schoolHag}</td>
              <td>{row.ajNum}</td>
              <td className="violinAttend">{row.violinAttend}</td>
              <td className="attend">{row.attend}</td>
              <td>{row.jBook}</td>
              <td>{row.jNum}</td>
              {writeUntilToday({
                lessons: row.getLessons,
                days: 29,
                dates: DATES,
              })}
              {/* {makeTags(3, "td")} */}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

// const ROTATE_TEACHER = gql`
//   mutation RotateTeacher($lessonId: String) {
//     rotateTeacher(lessonId: $lessonId)
//   }
// `;
