import { gql } from "@apollo/client";

export const CHUL = gql`
query Chul($yearMonth: String!)
{
  chul(yearMonth: $yearMonth){
    _id
    payday
    fee
    schoolHag
    name
    pJin
    attend
    violinAttend
    jBook
    jNum

    getLessons{
        teacher
        studentId
        studentName
        pJin
        date
        day
        yearMonth
        book
        no
    }
  }  
}
`;