import React, { useState } from 'react';
import { render } from 'react-dom';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { StudentsTable } from './studentTable.js';
import { StudentsTable1 } from './studentTable1.js';
import './index.css';

const client = new ApolloClient({
  //uri: "https://gma-orig-server.herokuapp.com/graphql",
  //uri: "https://gma-gql-server.herokuapp.com/graphql",
  uri: "https://gma-gql-server.fly.dev/graphql",
  // uri: "http://localhost:3900/graphql",
  // uri: 'http://localhost:5001',
  cache: new InMemoryCache(),
});

function App() {
  return (
    <ApolloProvider client={client}>
      <div>
        <div>
          <h2>2023년 3월 진도 + 출석부</h2>
          <StudentsTable1 />
          {/* 이전 버전 비교용 */}
          {/* <StudentsTable /> */}
        </div>
      </div>
    </ApolloProvider>
  );
}

render(<App />, document.getElementById('root'));
