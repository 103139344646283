import { v4 as uuidv4 } from 'uuid';

export const fieldSorter = (fields) => (a, b) =>
    fields
        .map((o) => {
            let dir = 1;
            if (o[0] === "-") {
                dir = -1;
                o = o.substring(1);
            }
            return a[o] > b[o] ? dir : a[o] < b[o] ? -dir : 0;
        })
        .reduce((p, n) => (p ? p : n), 0);

export function sortJindo(chul1, asc = true, emptyLine = false) { // 진도순으로 정렬한다.
    function filter(chul1, book, a, b) {
        return chul1.filter(s => s.jBook === book).sort(fieldSorter([a, b]))
    }
    let chul = []
    const books = ['바1', '바2', '바3', '바4', '체100', '체30', '체40', '체50']
    if (asc) {
        for (var b of books) {
            chul = chul.concat(filter(chul1, b, "jNum", "name"))
            if (emptyLine) chul = chul.concat([{ name: 'emptyLine' }])
        }
        return chul
    } else {
        for (var c of books.reverse()) {
            chul = chul.concat(filter(chul1, c, "-jNum", "name"))
            if (emptyLine) chul = chul.concat([{ name: 'emptyLine' }])
        }
        return chul
    }
}

function filter(array, filter) {
    function kinderGarten(array, age) {
        return array.filter(s => s.age === age)
    }
    function student(array, school, hag) {
        return array.filter(s => s.hag === hag && s.schoolShort.indexOf(school) === 2)
    }
    function adult(array, school) {
        return array.filter(s => s.schoolShort.indexOf(school) === 0 || s.schoolShort.indexOf(school) === 2)
    }
    function exists(a) {
        if (typeof a == "undefined") {
            return false
        } else
            if (typeof a == "number") {
                if (a === 0) return false
                else return true
            }
            else if (typeof a == "string") {
                if (a === '') return false
                else return true
            }
            else if (typeof a == "object") {
                if (a === null) return false
            }
            else
                return false
    }
    var result
    if (!exists(filter.age) && exists(filter.name) && !exists(filter.school) && !exists(filter.hag)) {
        result = [{ name: 'emptyLine' }]
    }
    if (!exists(filter.age) && exists(filter.school) && !exists(filter.hag)) {
        result = adult(array, filter.school)
    }
    if (exists(filter.age) && !exists(filter.school) && !exists(filter.hag)) {
        result = kinderGarten(array, filter.age)
    }
    if (!exists(filter.age) && exists(filter.school) && exists(filter.hag)) {
        result = student(array, filter.school, filter.hag)
    }
    return result
}

export function hagOrder(data) {
    let chul = []
    let chul1 = []
    chul1 = [...data.chul]

    const order = [
        { age: 6 },
        { age: 7 },
        { name: 'emptyLine' },
        { school: "초", hag: 1 },
        { name: 'emptyLine' },
        { school: "초", hag: 2 },
        { name: 'emptyLine' },
        { school: "초", hag: 3 },
        { name: 'emptyLine' },
        { school: "초", hag: 4 },
        { school: "초", hag: 5 },
        { school: "초", hag: 6 },
        { school: "중", hag: 1 },
        { school: "중", hag: 2 },
        { school: "중", hag: 3 },
        { school: "고", hag: 1 },
        { school: "고", hag: 2 },
        { school: "고", hag: 3 },
        { school: "성인" }
    ]

    for (var o of order) {
        chul = chul.concat(filter(chul1, o))
    }

    return chul
}

export function hagJindoOrder(data) {
    let chul = []
    let chul1 = []
    chul1 = [...data.chul]
    chul1 = chul1.map(({ jNum, ...rest }) => ({ ...rest, jNum: +jNum }));

    const order = [
        { age: 6 },
        { age: 7, emptyLine: true },
        { school: "초", hag: 1, emptyLine: true },
        { school: "초", hag: 2, emptyLine: true },
        { school: "초", hag: 3, emptyLine: true },
        { school: "초", hag: 4, emptyLine: true },
        { school: "초", hag: 5, emptyLine: true },
        { school: "초", hag: 6, emptyLine: true },
        { school: "중" },
    ]

    for (var o1 of order) {
        chul = chul.concat(sortJindo(filter(chul1, o1)))
        if (o1.emptyLine) chul = chul.concat([{ name: 'emptyLine' }])
    }

    return chul
}

export function makeTags(num, tag) {
    const tags = [];

    for (let i = 1; i <= num; i++) {
        if (tag === 'td') { tags.push(<td id={i} key={i} />); }
        if (tag === 'th') { tags.push(<th id={i} key={i} />); }
    }
    return <>{tags}</>
}

function teacherColor(teacher){
    switch (teacher){
        case 'YB': return 't2';
        case 'HR': return 't1';
        case 'HS': return 't4';
        case 'SB': return 't5';
        case '파트쌤': return 't3';
        case 'JA': return 't3';
        case '원장쌤': return 't0';
        default: return null;
    }
}

function rightBorderRed(n){
    return n%7==2?'rightBorderRed':''
}

export function writeUntilToday({ lessons, days, dates }) {// days : 날짜 칸수

    function onclick(lesson){
        lesson.display1()
    }

    if (typeof lessons === 'undefined') return null
    const tags = [];
    for (let d = 0; d < parseInt(days); d++) {
        let theDay = dates[d]
        let found = false
        for (let i = 0; i < lessons.length; i++) {
            if (parseInt(lessons[i].day) === parseInt(theDay)) {
                tags.push(<td id={i} key={i} 
                    className={`${teacherColor(lessons[i].teacher)} ${rightBorderRed(parseInt(theDay))}`}
                    onClick={()=>onclick(lessons[i])}
                    >{lessons[i].no}</td>);  // 디버그할때 날짜보기 : .no -> .day
                found = true
            }
        }
        if (!found)
            tags.push(<td id={uuidv4()} key={uuidv4()}  className={`${rightBorderRed(parseInt(theDay))}`}></td>);
    }
    return <>{tags}</>
}
